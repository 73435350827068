/* Conversation.css */

/* .Conversation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  } */
  
  .Conversation {
    flex-grow: 1;
    overflow-y: auto;
    height: 100%;
    position: relative;
  }
  
  .Conversation-message--human {
    background-color: #007bff;
    color: #fff;
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    margin-left: auto;
    max-width: 80%;
    word-wrap: break-word;
    margin-bottom: 0.5rem; /* Add this line for spacing between messages */
  }
  
  .Conversation-message--plant {
    background-color: #f1f1f1;
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    margin-right: auto;
    max-width: 80%;
    word-wrap: break-word;
    margin-bottom: 0.5rem; /* Add this line for spacing between messages */
  }
  