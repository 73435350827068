/* UserInput.css */

.user-input-form {
    display: flex;
    align-items: center;
    background-color: #f1f1f1;
    border-radius: 25px;
    padding: 0.25rem 1rem;
    width: 100%;
    margin: 0rem -1rem;
  }
  
  .user-input-field {
    flex-grow: 1;
    padding: 0.5rem;
    border-radius: 20px;
    border: none;
    outline: none;
    background-color: #fff;
    margin-right: 0.5rem;
  }
  
  .user-input-button {
    background-color: #007bff;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    outline: none;
  }
  
  .user-input-button:hover {
    background-color: #0056b3;
  }
  