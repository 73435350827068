.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f7f9fb;
    font-family: "Helvetica Neue", sans-serif;
  }
  
  .header {
    font-size: 2rem;
    font-weight: 700;
    color: #373a3c;
    margin-bottom: 2rem;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 6px;
    padding: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .label {
    font-size: 1.1rem;
    font-weight: 500;
    color: #373a3c;
    margin-bottom: 0.5rem;
  }
  
  .select {
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: #373a3c;
    border: 1px solid #ced4da;
    border-radius: 4px;
    margin-bottom: 1rem;
    outline: none;
  }
  
  .input {
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: #373a3c;
    border: 1px solid #ced4da;
    border-radius: 4px;
    margin-bottom: 1rem;
    outline: none;
  }
  
  .submit {
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .submit:hover {
    background-color: #0056b3;
  }  