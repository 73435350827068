.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 900px;
  width: 100%;
  padding: 2rem;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  flex-wrap: nowrap;
  width: calc(100% - 0px);
}


.chat-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  /* max-width: 900px; */
  min-height: 500px;
  justify-content: space-between;
  margin-left: 0rem;
  border: 2px solid #ccc;
  padding: 0px 1rem 0px 1rem;
  border-radius: 25px;
  /* height: calc(100% - 3.5rem); Adjust the height based on the UserInput height */
}

.Chat-messages {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  padding-bottom: 1rem;
  height: calc(100% - 25px);
}

.Chat-input {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

@media (min-width: 769px) {
  .content-wrapper {
    max-height: 500px;
  }

  .chat-wrapper {
    max-height: calc(100vh - 100px);
    overflow: hidden;
  }
}

@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
    align-items: center;
    position: relative;
    min-height: 100vh;
  }

  .chat-wrapper {
    height: inherit;
    overflow: hidden;
  }

  .SensorInput {
    display: none; /* Add this line */
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    transition: left 0.3s;
    z-index: 10;
  }

  .SensorInput-open {
    display: flex; /* Add this line */
    left: 0;
    right: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 0;
    padding: 1rem;
  }

  @media (min-width: 769px) {
    .SensorInput-open {
      position: static;
      background-color: transparent;
    }
  }
}


@media (max-width: 768px) {
  .HamburgerMenu-button {
    display: block;
  }
}

@media (min-width: 769px) {
  .HamburgerMenu-button {
    display: none;
  }
}

.HamburgerMenu-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
}

.HamburgerMenu-button-line {
  width: 25px;
  height: 3px;
  background-color: #c9c8c8;
  margin: 3px 0;
  display: block;
}

.HamburgerMenu-content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

