.SensorInput {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: black;
  color: white;
  margin: 50px;
  border-radius: 30px; /* Add this line */
  position: relative;
  z-index: 2;
}

.SensorInput-title {
  margin-bottom: 1rem; /* Modify this line */
}

.SensorInput-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 275px;
  padding: 1rem; /* Add this line */
}

.SensorInput-closeButton {
  display: none;
}

@media screen and (max-width: 768px) {
  .SensorInput {
    display: none;
  }
  
  .SensorInput-open {
    display: flex;
    position: absolute;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 0;
    margin: 0;
  }  
  
  .SensorInput-closeButton {
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 24px;
    border: none;
    background-color: transparent;
    color: #ffffff;
    cursor: pointer;
  }
}
